import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import { useEffect } from 'react';
import i18n from './utils/languages/i18n';

function App() {

  useEffect(() => {
    localStorage.removeItem("lng")
    i18n.changeLanguage('en')
  }, [])

  return (

    <BrowserRouter>
      <Navigation />
    </BrowserRouter>
  );
}

export default App;
