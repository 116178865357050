import './HeaderStyle.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Location } from 'iconsax-react'


function Header() {
    const { t, i18n } = useTranslation()
    let navigate = useNavigate()

    return (
        <div className='container-fluid zero'>
            <div className='row'>
                {/* Hidden md and lg size */}
                <div className='col-xs-12 zero'>
                    <div className='hidden-sm hidden-md hidden-lg col-xs-12 header-address-zone2'>
                        <span className='social-nav-icon col-xs-1'>
                            <Location size="18" className='just-location' />
                        </span>
                        <span className='header-address col-xs-10'>
                            {t("NavAddress")}
                        </span>
                    </div>
                </div>
                <div className='col-xs-12 zero'>
                    <a href='https://api.whatsapp.com/send?phone=905358459400' rel="noreferrer" target={"_blank"}>
                        <div className='hidden-sm hidden-md hidden-lg col-xs-12 header-address-zone2'>
                            <span className='whatsapp-icon2 col-xs-1'>
                                <ion-icon name="logo-whatsapp"></ion-icon>
                            </span>
                            <span className='header-address col-xs-10'>
                                +90 535 845 94 00
                            </span>
                        </div>
                    </a>
                </div>

            </div>
            <div className='container-fluid hidden-xs just-line zero'>
                <div className='container'>
                    <div className='row'>
                        {/* Hiddin xs and sm size */}
                        <div className='hidden-xs col-sm-6 header-address-zone'>
                            <span className='social-nav-icon'>
                                <Location size="18" className='just-location' />
                            </span>
                            <span className='header-address'>
                                {t("NavAddress")}
                            </span>
                        </div>
                        <div className='hidden-xs col-sm-6 social-side'>
                            <span className='follow-us-text hidden-xs hidden-sm'>{t("FollowUsText")}</span>
                            <a className='social-icon hidden-xs hidden-sm' href='https://www.instagram.com/dr.sinemcelik/'>
                                <ion-icon name="logo-instagram"></ion-icon>
                            </a>
                            <a className='social-icon hidden-xs hidden-sm' href='https://www.facebook.com/profile.php?id=100091074639391'>
                                <ion-icon name="logo-facebook"></ion-icon>
                            </a>
                            {/* <a className='social-icon hidden-xs hidden-sm' href='?/'>
                                <ion-icon name="logo-linkedin"></ion-icon>
                            </a> */}
                            <div className='whatsapp-zone'>
                                <span className='whatsapp-icon'>
                                    <ion-icon name="logo-whatsapp"></ion-icon>
                                </span>
                                <a className='whatsapp-link' rel="noreferrer" href='https://api.whatsapp.com/send?phone=905358459400' target={"_blank"}>
                                    <span>+90 535 845 94 00</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Navbar Starting */}
            <nav className="navbar navbar-default my-navbar">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand my-brand" href="/">{i18n.language === "tr" ? "Uzm." : null} Dr. SİNEM <span className='last-name'>ÇELİK</span></a>
                    </div>

                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-right">
                            <li><a href="/">{t("NavHomeTitle")}</a></li>
                            <li className="dropdown">
                                <a href="#/" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">{t("NavHomeServices")} </a>
                                <ul className="dropdown-menu">
                                    {/* <li><a href='#/' onClick={() => navigate("/botox")}>{t("NavSerBotox")}</a></li> */}
                                    {/* <li role="separator" className="divider"></li> */}
                                    {/* <li><a href="#/" onClick={() => navigate("/filling")}>{t("NavSerFilling")}</a></li> */}
                                    {/* <li role="separator" className="divider"></li> */}
                                    <li><a onClick={() => navigate("/ozon")}>{t("NavSerOzon")}</a></li>
                                    <li role="separator" className="divider"></li>
                                    {/* <li><a href="#/" onClick={() => navigate("/vitamin")}>{t("NavSerGlutathione")}</a></li> */}
                                    {/* <li role="separator" className="divider"></li> */}
                                    <li><a onClick={() => navigate("/dermaterapy")} >{t("NavSerDermapen")}</a></li>
                                    <li role="separator" className="divider"></li>
                                    <li><a onClick={() => navigate("/hair-loss")}>{t("NavSerHairLoss")}</a></li>
                                    <li role="separator" className="divider"></li>
                                    <li><a onClick={() => navigate("/spot")}>{t("NavSerSpot")}</a></li>
                                    {/* <li role="separator" className="divider"></li> */}
                                    {/* <li><a href="#/">{t("NavSerHand")}</a></li> */}
                                    {/* <li role="separator" className="divider"></li> */}
                                    {/* <li><a href="#/" onClick={() => navigate("/face-lift")}>{t("NavSerFace")}</a></li> */}
                                </ul>
                            </li>
                            <li className='hidden-sm hidden-md hidden-lg'><a href="tel:+905358459400">{t("NavReservation")}</a></li>
                            {/* {i18n.language === "en" ? null : <li><a href="#/">{t("NavBlog")}</a></li>} */}
                            <li><a onClick={() => navigate("/contact")}>{t("NavContact")}</a></li>
                            <li><a href="https://drsinemcelik.com/">TR</a></li>

                            {/* <li className="dropdown">
                                <a href="#/" className="dropdown-toggle" style={{ color: "gray" }} data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">{i18n.language === "en" ? "tr".toUpperCase() : null} {i18n.language === "tr" ? "en".toUpperCase() : null}  </a>
                                <ul className="dropdown-menu">
                                    <li><a href="?" onClick={() => localStorage.setItem('lng', "tr")}>TR</a></li>
                                </ul>
                            </li> */}
                            <li className='hidden-xs'>
                                <a href='tel:+905358459400'>
                                    <button>{t("NavReservation")}</button>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header